validateLabelCount = (event) ->

  count = $("#copies_count")[0].value

  if count > 9
    result = confirm("Are you sure you want to print " + count + " labels?")
    if result == false
      event.preventDefault();
      return false

  return true

setLinkPosition = (position) ->
  url = $("#google_maps_link").attr("href")
  url = url.replace(
    "Current+Location",
    position.coords.latitude + "," + position.coords.longitude
  )
  $("#google_maps_link").attr("href", url)

copyForms = ($form1, $form2) ->
  $(":input[name]", $form2).val ->
    $(":input[name='" + @name + "']", $form1).val()

registerCustomerEvents = ->
  $("form").on "click", ".remove_fields", (event) ->
    $(this).prev("input[type=hidden]").val("1")
    $(this).closest("fieldset").hide()
    event.preventDefault()

  $("form").on "click", ".add_fields", (event) ->
    time = new Date().getTime()
    regexp = new RegExp($(this).data("id"), "g")
    $(this).before($(this).data("fields").replace(regexp, time))
    event.preventDefault()

  $("#create_new_customer_form").on "click", (event) ->
    copyForms(
      $("#search_customer")[0],
      event.target.form
    )

  $("#print_property_label").click (event) ->
    validateLabelCount(event)

prepareCustomerScript = ->
  registerCustomerEvents()

  if $("#google_maps_link").length && navigator.geolocation
    navigator.geolocation.getCurrentPosition(setLinkPosition)


$(document).ready(prepareCustomerScript)
$(document).on("turbolink:load", prepareCustomerScript)
