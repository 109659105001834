calendarUpdate = ->
  $(".calendar_update").click ->
    date = $(this)[0].dataset.date
    $.get("workspace/calendar_update", {"new_date": date}, calendarUpdate, "script");

timePunch = ->
  $.ajax
    url: "/time_punch"
    type: "POST"
    dataType: "json"
    data: {}
    success: (data) ->
      toastr["success"]("#{data['message']}")
    error: (jqXHR, textStatus, errorThrown) ->
      toastr["error"]("Failed! #{textStatus}")

onWorkspacePage = ->
  calendarUpdate()

  $("#time_punch_button").click (e) ->
    e.preventDefault()
    timePunch()


$(document).ready(onWorkspacePage)
$(document).on('turbolink:load', onWorkspacePage)
