setCollapseEvents = ->
  $('.header').click (event) ->
    # event.preventDefault
    # console.log("Fired 1")
    $(this).toggleClass('expand').nextUntil('tr.header').slideToggle(10)
    # console.log("End")
    # return false

  $('.collapse_arrow').click ->
    $(this).toggleClass 'expand'
    $('#search_form').slideToggle(10)



$(document).ready(setCollapseEvents)
$(document).on('turbolink:load', setCollapseEvents)