# Receipt Dialog
dialogInitialize = (id, target, height) ->
  $(target).dialog({
    autoOpen: true,
    height: height,
    width: 550,
    modal: true,
    buttons: {
      "Close": ->
        $(this).dialog( "close" )
    },
    close: ->
      # location.href = location.href.split("?")[0]
  })


registerNewBillEvents = ->
  $(".email-invoice-button, .email-bill.button-to, .email-quote.button-to").click (e) ->
      e.preventDefault()

      id = this.dataset.serviceRequestId
      target = "#email_invoice_dialog_" + id

      dialogInitialize(id, target, 250)
      return false

  $(".saved-payment-method-button").click (e) ->
      e.preventDefault()

      id = this.dataset.serviceRequestId
      target = "#saved-payment-method-dialog-" + id

      dialogInitialize(id, target, 270)
      return false

  $("input.datalist_filter").on "focus", (e) =>
    e.target.placeholder = e.target.value
    e.target.value = ""

  $("input.datalist_filter").on "blur", (e) =>
    if e.target.value == "" && e.target.placeholder != ""
      e.target.value = e.target.placeholder

  $("form.edit_service_request").on "submit", (e) =>
    sessionStorage.scrollTop = $(window).scrollTop()

  if sessionStorage.scrollTop != "undefined"
    $(window).scrollTop(sessionStorage.scrollTop)
    sessionStorage.scrollTop = "undefined"


onBillPage = ->
  registerNewBillEvents()

$(document).ready(onBillPage)
$(document).on("turbolink:load", onBillPage)
