var idleTime = 0;
var bAfterLogout = localStorage.loggedOut || false;
$(document).ready(function() {
  localStorage.removeItem("loggedOut");

  /* Activating Best In Place */
  // jQuery(".best_in_place").best_in_place();

  /* Ping the server every interval */
  if (!(location.pathname == "/login" || location.pathname == "/workspace/login")) {
    var idleInterval = setInterval(ping, 30 * 1000); // 30 seconds
  }

  /* Zero the idle timer on mouse movement. */
  $(this).mousemove(function (e) {
    idleTime = 0;
  });
  /* Zero the idle timer on key press movement. */
  $(this).keypress(function (e) {
    idleTime = 0;
  });

  if (bAfterLogout && location.pathname == "/workspace/login") {
    setTimeout(function(){
      alert("You have been signed out of your workspace.");
    }, 2000);
  }

  /* Timeout flash notifications for success */
  setTimeout(function(){
    $(".flash, #notice").remove();
  }, 3500);
});

function ping() {
  idleTime++;

  var isActive;

  if (idleTime == 1) {
    isActive = "true";
  }
  else {
    isActive = "false";
  }

  $.ajax({
    url: "/active",
    data: { status: isActive },
    success: function(data) {
      if (data == "false") {
        $.ajax({
          url: "/timeout",
          success: function(data) {
            if (data == "true") {
              localStorage.setItem("loggedOut", true);
              window.location.href = "/workspace/logout?next=" + window.location.href;
            } else {
              toastr.success('Workspace signed out automatically.');
            }
          }
        });
      }
    },
    statusCode: {
      401: function() {
        window.location.href = "/logout?next=" + window.location.href;
      }
    }
  });
}
