setPerPageLimit = ->
  $("#limit_form select").change ->
    $("#limit_form").submit()
    setTimeout(
      ->
        location.href = location.href.replace(/&?page=([^&]$|[^&]*)/i, "")
      1500
    )

$(document).ready(setPerPageLimit)
$(document).on('turbolink:load', setPerPageLimit)