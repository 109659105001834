destroyGarlic = (target) ->
  aGarlicKeys = []
  i = 0
  len = localStorage.length
  while i < len

    if localStorage.key(i).indexOf(target) >= 0
      aGarlicKeys.push localStorage.key(i)
    ++i
  jQuery.each aGarlicKeys, (index, item) ->
    localStorage.removeItem item
    return
  return

findDataByValue = (value) ->
  if typeof value == 'undefined'
    return false

  i = 0
  len = localStorage.length
  while i < len
    currentValue = localStorage.getItem(localStorage.key(i))
    if currentValue == value.toString()
      return true
    ++i
  return false

checkKnowledgeSuccess = ->
  target = $("span[data-bip-attribute='problem']")
  value = target.data("bip-original-content")
  if findDataByValue(value) == true
    destroyGarlic('knowledge')

checkLineItemSuccess = ->
  target = $("p[data-service-request-id")
  if target
    value = target.data("service-request-id") + "/line_items"
    destroyGarlic(value)

# To add new custom garlic form clears, write an appropriate checkForSuccess
# function and add it to this function
clearGarlicForms = ->
  $("#clear_knowledge_form").click ->
    destroyGarlic('knowledge')
  checkKnowledgeSuccess()
  checkLineItemSuccess()

$(document).ready(clearGarlicForms)
$(document).on('turbolink:load', clearGarlicForms)
