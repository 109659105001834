printRefresh = ->
  $("#print_submit").click (event) ->
    setTimeout("location.reload();", 1000)

costEstimateCalculation = ->
  $("#service_request_services").change (event) ->
    $cost_estimate = 0.0
    selected = event.target.selectedOptions
    for item in selected
      $cost_estimate += parseFloat(item.getAttribute("data-cost"))

    $("#service_request_cost_estimate").val($cost_estimate)

@registerServiceRequestEvents = ->
  printRefresh()
  costEstimateCalculation()

  $("#new_recurring_invoice").click (e) ->
    # $("#recurring_invoice_dialog").dialog()
    $("#recurring_invoice_dialog").dialog({
      autoOpen: true, # Open dialog on click
      height: 400,
      width: 500,
      modal: true,
      buttons: {
      },
      close: ->
    })

    e.preventDefault()

$(document).ready(@registerServiceRequestEvents)
$(document).on("turbolink:load", @registerServiceRequestEvents)