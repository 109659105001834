registerPaymentEvents = ->
  # Return Button
  $("#partial_return_button").click (e) ->
    e.preventDefault()
    $("#payment_undo_dialog").dialog({
      width: 450,
      });

  # Date picker popup for date fields
  $("#search_start_datetime").AnyTime_picker({format: "%Y-%m-%d %H:%i", hideInput: false, placement: "popup" });
  $("#search_end_datetime").AnyTime_picker({format: "%Y-%m-%d %H:%i", hideInput: false, placement: "popup" });

onPaymentPage = ->
  registerPaymentEvents()

$(document).ready(onPaymentPage)
$(document).on("turbolink:load", onPaymentPage)
