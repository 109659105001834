function clipElement (elementId) {
    const text = document.getElementById(elementId).textContent

    navigator.clipboard.writeText(text)
        .then(() => {
            // console.log('success')
            global.toastr.success('Content copied to clipboard')
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        })
}

export default clipElement
