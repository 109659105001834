# root will be exported to global name space
root = exports ? this

readySections = (sectionClassName) ->
  $('.' + sectionClassName).sortable
    items: 'div'
    axis: 'y'
    update: ->
      updateSections(sectionClassName)
    start: ->
      fixedWidth(sectionClassName)

  $('.' + sectionClassName + '_item input').checkboxradio()
  $('.' + sectionClassName).on('change', handleToggle)

root.readySections = readySections

updateSections = (urlClass) ->
  updateUrl = $('.' + urlClass)[0].dataset.updateUrl
  section_settings = $('.' + urlClass + '_item')
  parameters = {}

  index = 0
  while index < section_settings.length
    key = section_settings[index].dataset.section
    value = parseInt(section_settings[index].dataset.position, 10)

    section_container = $('.workspace_section_container[data-container-section="' + key + '"]')[0]

    # Negative position numbers represent that that section has been disabled
    if value > 0
      position = index + 1
      section_container.style.display = ""
    else
      position = -(index + 1)
      section_container.style.display = "none"
    parameters[key] = position
    section_container.dataset.containerPosition = position

    index++

  $.post(updateUrl, parameters)

  workspace_sections = $('.workspace_section_container').sort((a, b) ->
    return Math.abs(~~a.dataset.containerPosition) > Math.abs(~~b.dataset.containerPosition)
  )

  workspace_sections.appendTo(".workspace_tabcontent")
  return


handleToggle = (e) ->
  target = $(e.target)

  section = target[0].id.substring(0, target[0].id.lastIndexOf('_'))
  record = $('div').find("[data-section='" + section + "']")[0]
  position = Math.abs(record.dataset.position)
  record.dataset.position = if target.is(':checked') then position else -position

  urlClass = record.className.substring(0, record.className.lastIndexOf('_'))
  updateSections(urlClass)

  return

fixedWidth = (sectionClassName) ->
    max_width = 0;
    content_div = $('.' + sectionClassName)

    # width is not calculated for display: none elements
    # so make sure width is calculated
    previous_css  = content_div.attr('style')

    content_div.css
      position:   'absolute'
      visibility: 'hidden'
      display:    'block'

    # get the max width from all the children
    children = content_div.children()
    for i in [0...children.length]
      do =>
        w = children[i].clientWidth
        if w > max_width
          max_width = w

    # reset css
    content_div.attr('style', if previous_css then previous_css else '')

    # set the width of the table to be the max
    content_div.css('min-width', max_width)

    return
