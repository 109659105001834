
addActiveOrders = (type, id) ->
  $parameter_key = type + "_id"
  $data = {}
  $data[$parameter_key] = id
  $old_count = updateShoppingCartCount()
  $.ajax
    url: "/active_orders"
    beforeSend: (xhr) ->
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
    type: "POST"
    dataType: "json"
    data: $data
    success: (data) ->
      $new_count = updateShoppingCartCount()
      if $new_count > $old_count
        toastr["success"](
          "Added " + ($new_count - $old_count).toString() + " item(s) to cart."
        )
      else
        toastr["error"]("No new items added.")
    error: (data) ->
      toastr["error"]("Add to cart failed!")

showCart = ->
  $("#load_cart_button").css("display", "none")
  $(".cart_line_item").each (index, element) ->
    $(element).css("display", "")

hideCart = ->
  $("#load_cart_button").css("display", "")
  $(".cart_line_item").each (index, element) ->
    $(element).css("display", "none")

calculateTotal = ->
  $amount = 0.0
  $(".visible_order_amounts td input:last-child").each (index, element) ->
    if $(element).is(":visible")
      if $(element).val() == ""
        return

      $amount += parseFloat($(element).val())

  return $amount

calculateOrderAmountsSum = ->
  $amount = calculateTotal()

  $("input#visible_payment_total").val($amount.toFixed(2))

ordersList = (reject = 0) ->
  $orders = []
  try $orders = JSON.parse(Cookies.get("orders"))
  catch e
  if reject != 0
    $orders = (order for order in $orders when order != reject)

  return $orders

transactionOrdersList = ->
  $orders = []
  try $orders = JSON.parse(Cookies.get("transaction_orders"))
  catch e

  return $orders

ordersInTransaction = ->
  $orders = ordersList()
  $transaction_orders = transactionOrdersList()
  for order in $orders
    if order in $transaction_orders
      return true

  return false

removeButton = (button) ->
  $order_row = $(button).closest("tr")
  $order_row.css("display", "none")

  $is_cart = false
  $order = parseInt($order_row.find("> input")[0].value)
  if $order == parseInt(Cookies.get("cart"))
    $is_cart = true

  $orders = []
  try $orders = JSON.parse(Cookies.get("orders"))
  catch e
  $orders = (order for order in $orders when order != $order)
  Cookies.set("orders", JSON.stringify($orders))

  if $is_cart
    # hideCart()
    $.ajax "/empty_cart",
      type: "POST",
      success: (data) ->
        localStorage.setItem("toastr_type", "success")
        localStorage.setItem("toastr_message", "Order removed.")

        location.href = location.href.split("?")[0]
      error: (data) ->
        toastr["error"]("Cart removal failed.")
        console.log(data)
  else
    updateShoppingCartCount()
    calculateOrderAmountsSum()

    localStorage.setItem("toastr_type", "success")
    localStorage.setItem("toastr_message", "Order removed.")

    location.href = location.href.split("?")[0]

updateShoppingCartCount = ->
  $cart = parseInt(Cookies.get("cart"))
  $cart_count = parseInt(Cookies.get("cart_count"))
  if Number.isNaN($cart_count)
    $cart_count = 0
  if $cart in ordersList()
    $orders_count = ordersList($cart).length
    if Number.isNaN($cart_count)
      $orders_count = 0
    $total_count = $orders_count + $cart_count
  else
    $total_count = ordersList().length

  $("#shopping_cart_count").text($total_count)
  return $total_count

# removeOrderFromOrdersList = ->
#   $($(".remove_button")[0].closest("tr")).find("> input")[0].value

registerOrderListEvents = ->
  # Remove Item Button
  $(".remove_button").click (e) ->
    e.preventDefault()
    if !$(this).parent().hasClass("disabled")
      removeButton(this)
    else
      toastr["error"](
        "Cannot remove this order from in-progress transaction. \
        Complete/close current sales transaction."
      )
    return false

  # Add Orders for Checkout
  $(".add_to_cart_button, .add_to_cart_link").click (e) ->
    e.preventDefault()
    addActiveOrders($(this).data("type"), $(this).data("id"))

  # Load Cart for Checkout
  $("#load_cart_button").click (e) ->
    e.preventDefault()

    $("#order_id").val($("#cart_list").data("cart_id"))
    $("#add_orders input[type=\"submit\"]")[0].click()
    $orders = []
    try $orders = JSON.parse(Cookies.get("orders"))
    catch e
    $orders.push Cookies.get("cart")
    Cookies.set("orders", JSON.stringify($orders))

  # Quick Pay Button
  $(".payment_button, .payment_link").click (e) ->
    # Clear other orders from cart
    if !$(this).hasClass("no_customer_payment_button")
      $orders = ordersList()
      $transaction_orders = transactionOrdersList()
      if $transaction_orders.length > 0 && confirm "This will close out the current sales transaction."
        transactionComplete()
        Cookies.set("quick_payment", "true")
      else if (
          (
            ($orders.length > 1 && $transaction_orders.length == 0) &&
            confirm "This will replace your current cart list."
          ) ||
          $transaction_orders.length == 0 && $orders.length <= 1
        )
        Cookies.remove("orders")
        Cookies.set("quick_payment", "true")
      else
        e.preventDefault()
        return false

  # Open Add To Transaction Dialog
  $("#add_item_button").click (e) ->
    e.preventDefault()
    $("#add_to_transaction_dialog").dialog
      autoOpen: true
      height: 380
      width: 276
      modal: true
      buttons:
        "Close": ->
          $( this ).dialog( "close" )
      close: ->
        #allFields.val("").removeClass "ui-state-error"
        location.reload(true)

  # Recalculate order amounts on change
  $(".visible_order_amounts td input:last-child").each (index, element) ->
    $(element).on "keyup change", (e) ->
      calculateOrderAmountsSum()


  $("#shopping_cart").click (e) ->
    Cookies.set("shopping_cart", "clicked")

  # Reset current sales transaction
  $("#reset_cart_button").click (e) ->
    $transaction_orders = transactionOrdersList()
    if $transaction_orders.length > 0 && confirm "This will close out the current sales transaction and VOID/RETURN all payments."
      $.ajax
        url: "/cancel_sales_transaction"
        type: "POST"
        success: (data) ->
          Cookies.remove("orders")
          Cookies.remove("transaction_orders")
          location.href = location.href.split("?")[0]
        error: (data) ->
    else if $transaction_orders.length == 0
      Cookies.remove("orders")
      Cookies.remove("transaction_orders")
    else
      e.preventDefault()
      return false

  # Prevent create payment if order amounts are zero
  $(".new_payment_button").click (e) ->
    $amount = calculateTotal()
    if $amount <= 0.0001
      e.preventDefault()
      toastr["error"](
        "Please set at least one 'Amount To Pay' \
        greater than zero before taking a payment."
      )
      return false

runToastr = ->
  $type = localStorage.getItem("toastr_type")
  localStorage.removeItem("toastr_type")

  $message = localStorage.getItem("toastr_message")
  localStorage.removeItem("toastr_message")

  if $type && $type.length && $message && $message.length
    toastr[$type]($message)

onPaymentPage = ->
  registerOrderListEvents()
  calculateOrderAmountsSum()
  updateShoppingCartCount()

  $related_height = $("div#related_payments").height()
  $fieldset_height = $("fieldset.payment").height()
  if $related_height > $fieldset_height - 60
    $("fieldset.payment").height($("div#related_payments").height() + 60)
    $("#payment_update_button").css("margin-top", $("fieldset.payment").height() - $fieldset_height + 10)

  $orders = []
  try $orders = JSON.parse(Cookies.get("orders"))
  catch e

  if parseInt(Cookies.get("cart")) in $orders
    showCart()
  else
    hideCart()

  runToastr()


$(document).ready(onPaymentPage)
$(document).on("turbolink:load", onPaymentPage)

# Code from new_payment.coffee
onPaymentCompletion = (response) ->
  # if response["closeIFrame"] == true
  #   return

  $note = $("#payment_note").val()
  # alert(JSON.stringify(response))
  $log_note = "order_amounts: " + buildOrderAmountsParam() + "\n" +
    "\n orginal note: '" + $note + "'\n" +
    "\n response: " + JSON.stringify(response)
  $("#payment_note").val($log_note)

  $.ajax "/payments/manual_card_entry",
    type: "POST",
    dataType: "json",
    data: {
      "order_amounts": buildOrderAmountsParam(),
      "note": $note,
      "response": JSON.stringify(response),
      "payment_note": $log_note
      },
    error: (jqXHR, textStatus, errorThrown) ->
      alert(textStatus + " - " + errorThrown)
    success: ->
      updateShoppingCartCount()
      location.href = location.href.split("?")[0]
      # alert('success!')

  return

positionOn = (original, to_move, padding = 0) ->
    $position = original.position()

    $x      = $position.left
    $y      = $position.top

    to_move.css({
      position: "absolute",
      zIndex:   5000,
      top:      $y + padding,
      left:     $x + padding
    })

copyOrderAmounts = ->
  $orders = $(".order_amounts input")
  $visible_orders = $(".visible_order_amounts input")

  $orders.each (index, element) ->
    $visible_element = $visible_orders[index]

    if index % 2 == 0
      # Order ID
      if $(element).val() != $($visible_element).val()
        console.log($(element).val() + " not equal to " + $($visible_element).val())
    else
      # Order Amount
      $(element).val($($visible_element).val())

  $("#hidden_payment_total").val($("#visible_payment_total").val())


enableField = (field, msg = null) ->
  $(field).removeAttr("disabled")
  if msg == null
    $(field).removeAttr("title")
  else
    $(field).attr('title', msg)

disableField = (field, msg) ->
  $(field).attr('disabled','disabled')
  $(field).attr('title', msg)

showHideFields = ->
  if $("#tender_select").val() == "check"
    enableField("#check_number", "Numbers and dashes only")
    enableField("#visible_payment_total")
    disableField("#card_number", "Select tender 'Debit Card' or 'Credit Card' to enable.")
  else if $("#tender_select").val() == "cash"
    enableField("#visible_payment_total")
    disableField("#card_number", "Select tender 'Debit Card' or 'Credit Card' to enable.")
    disableField("#check_number", "Select tender 'Check' to enable.")
  else if $("#tender_select").val() in ["credit_card", "debit_card"]
    enableField("#card_number")
    disableField("#check_number", "Select tender 'Check' to enable.")
    disableField("#visible_payment_total", "Automatically calculated from 'Amount To Pay' fields.")
  else
    disableField("#card_number", "Select tender 'Debit Card' or 'Credit Card' to enable.")
    disableField("#check_number", "Select tender 'Check' to enable.")
    disableField("#visible_payment_total", "Automatically calculated from 'Amount To Pay' fields.")

buildOrderAmountsParam = ->
  $order_amounts = "["
  $orders = $(".order_amounts input")
  $orders.each (index, element) ->
    if index % 2 == 0
      $order_amounts += "{\"order_id\":" + $(element).val() + ","
    else
      $order_amounts += "\"amount\":" + $(element).val() + "}"
      if index != $orders.length - 1
        $order_amounts += ","

  $order_amounts += "]"

# Receipt Dialog
dialogInitialize = ->
  auto_open = false
  if Cookies.get("sale_complete")
    auto_open = true
    Cookies.remove("sale_complete")

  $("#receipt_dialog").dialog({
    autoOpen: auto_open,
    height: 250,
    width: 450,
    modal: true,
    buttons: {
      "Return to Transaction": ->
        location.href = location.href.split("?")[0]
      "No Receipt/Close": ->
        $(this).dialog( "close" )
    },
    close: ->
      transactionComplete()
  })

transactionComplete = ->
  $.ajax
    url: "/close_sales_transaction"
    type: "GET"
    success: (data) ->
      Cookies.remove("orders")
      Cookies.remove("transaction_orders")
      location.href = location.href.split("?")[0]
    error: (data) ->


registerNewPaymentEvents = ->
  # Manual Payment Button
  $("#velocity_button_placeholder").click (e) ->
    e.preventDefault()

    $orders = []
    try $orders = JSON.parse(Cookies.get("orders"))
    catch e
    if $orders.length <= 0
      toastr["error"](
        "Please add an order to the cart before taking a payment!"
      )
      return false

    $amount = calculateTotal()
    if $amount <= 0.0001
      toastr["error"](
        "Please set at least one 'Amount To Pay' \
        greater than zero before taking a payment."
      )
      return false

    copyOrderAmounts()

    return false


  # Manual Complete Payment
  $("#manual_complete_transaction > a").click (e) ->
    e.preventDefault()
    if transactionOrdersList().length == 0
      toastr["error"]("No transaction has been started.")
    else
      $("#receipt_dialog").dialog("open")

  $("#tender_select").change ->
    showHideFields()

  # Email Receipt
  $("input[name='email_receipt']").click (e) ->
    if Cookies.get("keyed_card_payment")
      if confirm "Hosted Checkout sends a receipt to the previously entered email. Do you want to email another receipt?"
        Cookies.remove("keyed_card_payment")
        $(this).click()
        return

      e.preventDefault()
      return false

  # Print Receipt
  $("[name='print_receipt']").click (e) ->
    e.preventDefault()
    $("#sales_transaction_email").removeAttr("required")
    $("#sales_transaction_email").removeAttr("type")
    $(this).unbind()
    $(this).click()
    callback = -> location.reload()
    setTimeout callback, 2000

  # Submit New Payment Form
  $("#new_payment_form_2").submit (e) ->
    e.preventDefault()
    copyOrderAmounts()
    e.currentTarget.submit()


onPaymentNewPaymentsPage = ->
  registerNewPaymentEvents()
  showHideFields()
  dialogInitialize()
  $("#add_item_input").focus()

  # The manual card payment uses this as a callback. Webpack doesn't
  # allow @ to expose functions globally so we must attach the function
  # to the window.
  global.onPaymentCompletion = onPaymentCompletion


$(document).ready(onPaymentNewPaymentsPage)
$(document).on("turbolink:load", onPaymentNewPaymentsPage)
